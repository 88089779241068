import React from 'react';
import IcNotFound from '@icons/ic-not-found.png';

function NotFound() {
  return (
    <div className="container d-flex flex-column align-items-center justify-content-center py-5 my-3 flex-grow-1">
      <img src={IcNotFound} className="mb-3" />
      <h1 className="dosis-title-1 text-philadelphia-blue">404: Not Found</h1>
      <div className="asap-subhead text-coal-black">You just hit a route that doesn&#39;t exist... the sadness.</div>
    </div>
  )
}

export default NotFound;
